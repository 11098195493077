(function ($, Drupal) {
  Drupal.behaviors.contentBlockHeroV1 = {
    attach: function (context) {
      var $modules = $('.js-content-block-hero--v1', context);
      var isDesktop = Unison.fetch.now().name === 'landscape';
      var updateResponsiveFn = function () {
        // eslint-disable-next-line complexity
        $modules.each(function () {
          var $module = $(this);
          var $text = $('.js-content-block-content-text', $module);
          var $media = $('.js-content-block-media', $module);
          var $video = $('.js-video--icon-only', $module);
          var $contentInner = $('.content-block__inner', $module);
          var iconPlacement = $module.data('video-icon-placement');

          if (isDesktop && $video.length) {
            if (iconPlacement !== 'bottom_right') {
              $video.appendTo($text);
            } else {
              $video.appendTo($contentInner);
            }
          }
          if (!isDesktop && $video.length) {
            $video.prependTo($media);
          }
          if ($text.outerHeight(true) > $media.outerHeight(true)) {
            if (isDesktop || $module.hasClass('js-mobile-text-over-image')) {
              $module.css({ 'min-height': $text.outerHeight(true) });
              $('.js-content-block-media img, .js-content-block-media video', $module).css({
                'min-height': $text.outerHeight(true)
              });
            } else {
              $module.css({ 'min-height': '0' });
            }
          }
        });
      };

      MantleWindow.removeEvents('hero-calculate');
      MantleWindow.onResize(updateResponsiveFn, 'hero-calculate', 25);
      setTimeout(updateResponsiveFn, 300);
    }
  };
})(jQuery, Drupal);
